import React from 'react'

import Layout from 'components/Layout/Layout';
import Requirements from 'components/Requirements/Requirements';
import SEO from 'components/seo';

const RequirementsPage = () => (
  <Layout>
    <SEO title="Requirements" />
    <Requirements />
  </Layout>
)

export default RequirementsPage
