import React from 'react';
import PropTypes from 'prop-types';
import styled, { css }  from 'styled-components';
import { CustomInput } from 'reactstrap';

import Check from 'images/check.svg';

const StyledRadio = styled(CustomInput)` 
  padding-left: 24px; 
  .custom-control-label {
    ${props => props.theme.main.fonts.body.normal};
    padding-top: 4px;
    padding-left: 8px;
    cursor: pointer;
    &:before {
      left: -24px;
      width: 24px;
      height: 24px;
      border: solid 2px ${props => props.theme.main.colors.grey.base};
      background-color: transparent;
    }
    &:after {
      left: -24px;
      width: 24px;
      height: 24px;
    }
  }
  .custom-control-input {
    ${props => props.disabled === true && css`
      &~.custom-control-label {
        color: ${props => props.theme.main.colors.grey.base};
      }
    `}

    &:not(:disabled):active {
      &~.custom-control-label {
        &:before {
          background-color: ${props => props.theme.main.colors.secondary.light1};
          border-color: ${props => props.theme.main.colors.secondary.light1};
        }
      }
    }
    &:focus {
      &~.custom-control-label {
        &:before {
          box-shadow: none;
          border-color: ${props => props.theme.main.colors.secondary.base};
        }
      }
    }
    &:hover {
      &~.custom-control-label {
        &:before {
          border-color: ${props => props.theme.main.colors.secondary.base};
        }
      }
    }
    &:checked {
      &~.custom-control-label {
        &:before {
          background-color: ${props => props.theme.main.colors.secondary.base};
          border-color: ${props => props.theme.main.colors.secondary.base};
        }
        &:after {
          background-image: url(${Check});    
          background-size: 60% 50%;
        }
      }
    }
    &:disabled {
      &~.custom-control-label {
        color: ${props => props.theme.main.colors.grey.base};
        cursor: not-allowed;
        &:before {
          background-color: ${props => props.theme.main.colors.neutral.offwhite};
          border-color: ${props => props.theme.main.colors.neutral.line};
        }
      }
    }
  }
`;

const Radio = ({label, value, name, onChange, selectedValue, disabled = false, inline=false}) => {
  const handleChange = () => {
    onChange({target: {name: name, value: value }});
  }

  return (<StyledRadio type="radio" id={`${name}-${value}`} name={name} label={label} inline={inline} onChange={handleChange} checked={(selectedValue === value)} disabled={disabled} tabIndex="0" aria-disabled={disabled} />);
}

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
  disabled: PropTypes.bool
}

export default Radio;