import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { updateRequirements, updateEligibility } from 'actions/actions';

import Form from 'components/Common/Form/Form';
import Header from 'components/Common/Layout/Header';
import Radio from 'components/Common/Form/Radio';
import CheckboxGroup from 'components/Common/Form/CheckboxGroup';
import Navigation from 'components/Common/Layout/Navigation';

export default React.memo(() => {
  const dispatch = useDispatch();
  const { cifId, applicationId, accessToken, isMobile } = useSelector(state => state.reducer);
  const { depositIncome, socialAssistance } = useSelector(state => state.reducer.requirements);
  const [state, setState] = useState({
    depositIncome: depositIncome || null,
    socialAssistance: socialAssistance || null,
  });

  const handleChange = (event) => {
    let { name, value } = event.target;   
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  const submit = (e) => {
    e.preventDefault();
    if(state.depositIncome && state.socialAssistance) {
      dispatch(updateRequirements({depositIncome: state.depositIncome, socialAssistance: state.socialAssistance}));
      dispatch(updateEligibility({
        cifId: cifId,
        applicationId: applicationId,
        eligibilityChecks: {
          isIncomeDepositInVancity: state.depositIncome,
          isReceivingSocialAssistance: state.socialAssistance
        }
      }, accessToken, isMobile))
      if(state.depositIncome === 'true' && state.socialAssistance === 'false') {
        navigate('income-verification', { replace: true });
      }
      else {
        navigate('error', { replace: true, state: {errorCode: 400}});
      }
    }
  }

  const back = () => {
    navigate('loan-calculator', { replace: true });
  }
  return (
    <Form>
      <Header>Loan requirements</Header>
      <CheckboxGroup label="Is your income deposited at Vancity?">
        <p>In order to qualify, your income must be deposited into a Vancity account.</p>
        <div>
          <Radio name="depositIncome" value="true" label="Yes" onChange={handleChange} selectedValue={state.depositIncome} />
          <Radio name="depositIncome" value="false" label="No" onChange={handleChange} selectedValue={state.depositIncome} />
        </div>
      </CheckboxGroup>
      <CheckboxGroup label="Are you on Social Assistance (excluding Disability Assistance)?">
        <p>Loan products may impact your eligibility to receive Social Assistance. For help, please contact your nearest branch or our member service centre.</p>
        <div>
          <Radio name="socialAssistance" value="true" label="Yes" onChange={handleChange} selectedValue={state.socialAssistance} />
          <Radio name="socialAssistance" value="false" label="No" onChange={handleChange} selectedValue={state.socialAssistance} />
        </div>
      </CheckboxGroup>
      <Navigation buttonLabel="Confirm" onClickForward={submit} onClickBack={back} disabled={!(state.depositIncome && state.socialAssistance)} />
    </Form>);
});