import React from 'react';
import PropTypes from 'prop-types';
import styled  from 'styled-components';
import { FormGroup } from 'reactstrap';

import Label from 'components/Common/Form/Label';

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 32px;
  &>label {
    margin-bottom: 16px;
  }
  p {
    ${props => props.theme.main.fonts.body.normal};
    color: ${props => props.theme.main.colors.grey.base};
    margin-bottom: 8px;
  }
  .custom-radio {
   padding-top: 8px;
   padding-bottom: 8px;
   ${props => props.theme.main.fonts.body.normal};
  }
`;

const Description = styled.p`
  &&& {
    margin-bottom: 24px;
  }
`;

const CheckboxGroup = ({label, description = null, children}) => {
  return (
    <StyledFormGroup>
      <Label>{label}</Label>
      {description ? <Description>{description}</Description> : null}
      {children}
    </StyledFormGroup>
  );
}

CheckboxGroup.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  description: PropTypes.string
}

export default CheckboxGroup;